import {Injectable, OnDestroy} from '@angular/core';
import {defer, firstValueFrom, from, iif, isObservable, Observable, of, Subject, throwError} from 'rxjs';
import {Actions, ofActionSuccessful, Store} from '@ngxs/store';
import {CancelEvent as CancelEventAction, CompleteEvent, DispatchEvent} from './event.actions';
import {filter, switchMap, take, takeUntil} from 'rxjs/operators';
import {isPromiseLike, uniqId} from '@matchsource/utils';

type Event<T = any> = {type: string; id: string; payload: T};

export class CancelEvent<T = any> {
  constructor(public readonly reason?: T) {}
}

type EventHandler<T = any> = (data: T) => void | CancelEvent | any;

type EventHandlerWrapper = {type: string; id: string; handler: EventHandler};

const generateEventId = () => uniqId('event-');

const eventHandlerWrapperFactory = (type: string, handler: EventHandler): EventHandlerWrapper => ({
  type,
  handler,
  id: uniqId('event-handler-'),
});

const fromEventHandler = (input: any, defaultData: any): Observable<any> => {
  return isObservable(input) || isPromiseLike(input) ? from(input) : of(input !== undefined ? input : defaultData);
};

@Injectable({
  providedIn: 'root',
})
export class EventService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private readonly handlers: {[key: string]: EventHandlerWrapper[]} = {};

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions
  ) {
    this.actions$
      .pipe(ofActionSuccessful(DispatchEvent), takeUntil(this.destroy$))
      .subscribe(({eventType: type, payload, id}: DispatchEvent) => this.handleEvent({type, id, payload}));
  }

  dispatch<T = any>(eventType: string, payload?: T) {
    const eventId = generateEventId();

    this.store.dispatch(new DispatchEvent<T>(eventType, eventId, payload));
  }

  dispatchAsyncWithResult<T = any>(eventType: string, payload?: T): Promise<any> {
    const eventId = generateEventId();

    const result = firstValueFrom(
      this.actions$.pipe(
        ofActionSuccessful(CompleteEvent, CancelEventAction),
        filter(({id}: CompleteEvent | CancelEventAction) => id === eventId),
        switchMap(event =>
          iif(
            () => event instanceof CompleteEvent,
            of((event as CompleteEvent).result),
            throwError((event as CancelEventAction).reason)
          )
        )
      )
    );
    this.store.dispatch(new DispatchEvent<T>(eventType, eventId, payload));

    return result;
  }

  on<T = any>(eventType: string, handler: EventHandler<T>, unsubscribeOn?: Observable<any>): () => void {
    const eventHandlerWrapper = eventHandlerWrapperFactory(eventType, handler);

    this.addHandler(eventHandlerWrapper);

    unsubscribeOn?.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
      this.off(eventHandlerWrapper.type, eventHandlerWrapper.id);
    });

    return () => this.off(eventHandlerWrapper.type, eventHandlerWrapper.id);
  }

  private off(eventType: string, handlerId?: string) {
    if (!this.hasEventHandlers(eventType)) {
      return;
    }

    if (!handlerId) {
      delete this.handlers[eventType];
    } else {
      this.handlers[eventType] = this.handlers[eventType].filter(({id}) => id !== handlerId);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private hasEventHandlers(eventType: string): boolean {
    return eventType in this.handlers;
  }

  private handleEvent(event: Event): void {
    const {type, payload, id} = event;

    if (!this.hasEventHandlers(type)) {
      this.store.dispatch(new CompleteEvent(id, payload));
      return;
    }

    const eventHandlers = this.handlers[type];
    Observable.prototype.pipe
      .apply(
        of(payload),
        // @TODO: Should be refactored to avoid ts-ignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [
          ...eventHandlers.map(({handler}) => {
            return switchMap((data: any) =>
              defer(() => {
                return data instanceof CancelEvent ? throwError(data.reason) : fromEventHandler(handler(data), data);
              })
            );
          }),
          takeUntil(this.destroy$),
        ]
      )
      .subscribe({
        next: (result: any) => {
          this.store.dispatch(new CompleteEvent(id, result));
        },
        error: (error: any) => {
          this.store.dispatch(new CancelEventAction(id, error));
        },
      });
  }

  private addHandler(handler: EventHandlerWrapper): void {
    const {type} = handler;

    if (!this.hasEventHandlers(type)) {
      this.handlers[type] = [];
    }

    const typeHandlers = this.handlers[type];
    typeHandlers.unshift(handler);
  }
}
