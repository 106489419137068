export class DispatchEvent<T = any> {
  static readonly type = '[Event] Dispatch';

  constructor(
    public readonly eventType: string,
    public readonly id: string,
    public readonly payload?: T
  ) {}
}

export class CancelEvent<T = any> {
  static readonly type = '[Event] Cancel';

  constructor(
    public readonly id: string,
    public readonly reason: T
  ) {}
}

export class CompleteEvent<T = any> {
  static readonly type = '[Event] Complete';

  constructor(
    public readonly id: string,
    public readonly result: T
  ) {}
}
